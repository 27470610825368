<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'primary'" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title
        :class="{'form-danger': isDelete, 'form-info': isUpdate, 'form-primary': isCreate}"
      >
        <span v-if="isCreate" class="">{{ $('create') }}</span>
        <span v-if="isUpdate" class="">{{ $('update') }}</span>
        <span v-if="isDelete" class="">{{ $('delete') }}</span>
      </v-card-title>
      <v-card-text>
        <h2 v-if="isDelete && item" class="my-3">
          {{ $('delete_msg', [item.name]) }}
        </h2>
        <v-form
          v-if="!isDelete"
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.title"
                  v-max-length="200"
                  :label="$('title')"
                  :rules="rules.title"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.discount"
                  v-max-length="100"
                  :label="$('discount')"
                  :rules="rules.discount"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  v-model="form.description"
                  :label="$('description')"
                  :rules="rules.description"
                  required
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <label>{{ $('expire') }}</label>
                <date-picker
                  v-model="form.expire"
                  style="max-width: 600px; width: 100%; margin: 0 auto"
                  class="mb-5"
                  :label="$('expire')"
                  value-type="format"
                ></date-picker>
              </v-col>

              <v-col
                :cols="imagePreview ? 9 : 12"
              >
                <v-file-input
                  ref="file"
                  v-model="form.image"
                  type="file"
                  accept="image/png, image/jpeg, image/bmp"
                  :placeholder="$('image_select')"
                  prepend-icon="mdi-camera"
                  :label="$('image')"
                  @change="fileChanged"
                ></v-file-input>
              </v-col>
              <v-col
                v-if="imagePreview"
                cols="3"
              >
                <v-avatar
                  color=""
                  class="v-avatar-light-bg primary--text mb-1 "
                  size="100"
                  rounded
                >
                  <v-img
                    :src="imagePreview"
                  >
                  </v-img>
                </v-avatar>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="form.company_id"
                  :items="companies"
                  :search-input.sync="search"
                  :loading="isLoading"
                  :label="$('company_id')"
                  item-value="id"
                  item-text="name"
                  outlined
                  hide-details
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-switch
                  v-model="form.active"
                  :label="$('active')"
                  dense
                  inset
                  color="primary"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          v-if="isCreate"
          color="primary"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.create') }}
        </v-btn>
        <v-btn
          v-if="isUpdate"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
        <v-btn
          v-if="isDelete"
          color="error"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import DatePicker from 'vue2-datepicker'
import toast from '@/store/toast'

import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ar-sa'

export default {
  name: 'OfferDialog',
  components: { DatePicker },
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    loading: true,
    isLoading: true,
    companies: [],
    search: '',
    submitLoading: false,
    valid: false,
    item: null,
    imagePreview: null,
    form: {
      title: '',
      description: '',
      discount: '',
      expire: '',
      active: true,
      company_id: null,
      image: null,
      order: 0,
    },
  }),
  computed: {
    isCreate() {
      return this.data.type === 'create'
    },
    isUpdate() {
      return this.data.type === 'update'
    },
    isDelete() {
      return this.data.type === 'delete'
    },
    rules() {
      const rules = {};
      rules.title = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.title`)]),
      ];

      rules.discount = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.discount`)]),
      ];

      rules.expire = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.expire`)]),
      ];

      rules.active = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.active`)]),
      ];

      rules.company_id = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.company_id`)]),
      ];
      rules.image = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.image`)]),
        v => (v && v.size < 3072) || this.$t(`${this.translationKey}.modal.form.image`),
      ];

      return rules;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (val.id) {
            const offer = await axios.get(`/offer/${val.id}`);
            this.item = offer.data.data;
            this.form.title = this.item.title;
            this.form.description = this.item.description;
            this.form.discount = this.item.discount;
            this.form.expire = this.item.expire;
            this.form.active = this.item.active;
            this.form.company_id = this.item.company_id;
            if (this.item.image) {
              this.imagePreview = this.item.image.original_url;
            }
          }
          if (!val.dialog) {
            this.resetForm();
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
    search: {
      immediate: true,
      async handler(val) {
        await this.fetchCompanies(val);
      },
    },
  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true;
        if (this.isDelete) {
          await axios.delete(`/offer/delete/${this.data.id}`);
          await toast.dispatch('success', this.$t('form.success.delete'))
          this.$emit('update:table')
          this.dialogModal(false)

          return;
        }
        if (this.$refs.form.validate() === false) {
          return;
        }
        const formData = new FormData();
        if (this.form.image) {
          formData.append('image', this.form.image);
        }
        formData.append('title', this.form.title);
        formData.append('company_id', this.form.company_id);
        formData.append('description', this.form.description);
        formData.append('discount', this.form.discount);
        formData.append('expire', this.form.expire);
        formData.append('active', (+this.form.active).toString());

        if (this.isCreate) {
          await axios.post('/offer/create', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          await toast.dispatch('success', this.$t('form.success.create'))
        } else if (this.isUpdate) {
          await axios.post(`/offer/edit/${this.data.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          await toast.dispatch('success', this.$t('form.success.update'))
        }
        this.$emit('update:table')
        this.dialogModal(false)
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false;
      }
    },
    async fetchCompanies(val) {
      try {
        if (this.isLoading) return
        this.isLoading = true
        const companies = await axios.get(`/company/?search=${val || ''}`);
        this.companies = companies.data.data;
      } catch (error) {
        await toast.dispatch('error', error.response ? error.response.data.message : error.message);
      } finally {
        this.isLoading = false
      }
    },
    fileChanged() {
      if (!this.form.image) {
        this.imagePreview = null

        return null
      }
      const reader = new FileReader()
      reader.readAsDataURL(this.form.image)
      reader.onload = () => {
        this.imagePreview = reader.result
      }
      reader.onerror = error => {
        console.log('Error: ', error)
      }

      return null
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        title: '',
        description: '',
        discount: '',
        expire: '',
        active: true,
        company_id: null,
        image: null,
        order: 0,
      };
      this.item = null;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },
}
</script>

<style scoped>

</style>
